

import React, { useState, useEffect } from 'react';
import AWS from 'aws-sdk';
import {S3 } from "aws-sdk";
import './S3FileViewer.css';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { adminUsernames } from './adminUsers';
import {NavbarDemo} from './components/ui/NavbarDemo.tsx';
import { Button } from './components/ui/moving-border.tsx';



const S3FileViewer = () => {
  const { isLoggedIn,logout,username} = useAuth();
  const [visibleSubfolderCount, setVisibleSubfolderCount] = useState(10);

  const history = useNavigate();
  const [userFolders, setUserFolders] = useState({});
  const [visibleUserId, setVisibleUserId] = useState(null);
  const [visibleSubfolder, setVisibleSubfolder] = useState({});
  const [jobStatus, setJobStatus] = useState({});
  // Parse the date from the file name
 

  useEffect(() => {
    const isUserLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
  if (!isLoggedIn) {
    history('/', { replace: true }); // Redirects to login page if not logged in
    return
  } 
  
  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID, 
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: process.env.REACT_APP_AWS_REGION,
  });
  console.log()
    // Create an S3 service object
    const s3 = new AWS.S3();

    // Specify the bucket name
    const params = {
      Bucket: 'artiamas-testing-bucket',
    };

    // Fetch the list of objects in the bucket
    s3.listObjects(params, (err, data) => {
      if (err) {
        console.error('Error fetching S3 files:', err);
        
      } else {
        let filesByUserAndSubfolder = data.Contents.reduce((acc, file) => {
          const [userId, subfolder, ...rest] = file.Key.split('/');
          const filePath = rest.join('/');
          const totalPath = file.Key; 
          
          acc[userId] = acc[userId] || {};
          acc[userId][subfolder] = acc[userId][subfolder] || [];
          acc[userId][subfolder].push(filePath);
          return acc;

        // Process the list of objects to group files by user ID and subfolders
      
        }, {});
      
        Object.keys(filesByUserAndSubfolder).forEach(userId => {
          const subfolders = Object.keys(filesByUserAndSubfolder[userId]);
          subfolders.forEach(subfolder => {
   
          });

          filesByUserAndSubfolder[userId] = subfolders.reverse().reduce((obj, key) => {
            obj[key] = filesByUserAndSubfolder[userId][key];
            updateJobStatus(userId, key);
            return obj;
          }, {});
        
        });
        
       

    setUserFolders(filesByUserAndSubfolder);
      }
    });
  }, [isLoggedIn, history]);

  const handleLogout = () => {
    logout();
    history('/', { replace: true });
  };
  const updateJobStatus = (userId, subfolder) => {
    const s3 = new AWS.S3();
    const outputFileKey = `${userId}/${subfolder}/output.csv`;
    const statusFileKey = `${userId}/${subfolder}/status.txt`;
    const params = {
      Bucket: 'artiamas-testing-bucket',
    };
    s3.headObject({ Bucket: params.Bucket, Key: outputFileKey }, (outputErr, outputData) => {
      if (!outputErr) {
        // output.csv exists
        setJobStatus(prevState => ({
          ...prevState,
          [`${userId}/${subfolder}`]: {
            status: 'Complete',
            link: `https://${params.Bucket}.s3.${AWS.config.region}.amazonaws.com/${outputFileKey}`
          }
        }));
      } else {
        // output.csv does not exist, check for status.txt
        s3.getObject({ Bucket: params.Bucket, Key: statusFileKey }, (statusErr, statusData) => {
          if (!statusErr) {
            // status.txt exists
            const statusText = statusData.Body.toString('utf-8');
            const firstLine = statusText.split('\n')[0] || 'Unprocessed';
            setJobStatus(prevState => ({
              ...prevState,
              [`${userId}/${subfolder}`]: { status: firstLine, link: null }
            }));
          } else {
            // Neither file exists
            setJobStatus(prevState => ({ ...prevState, [`${userId}/${subfolder}`]: { status: 'Unprocessed', link: null } }));
          }
        });
      }
    });
  

  
  };
  const sendStopMessage = (jobId) => {
    const queryString = new URLSearchParams({ 'job-id': jobId }).toString();
    const url = `https://artiamas-cloud-viewer.org/stop?${queryString}`;
  
    fetch(url, {
      method: 'POST',
      // Headers may not be necessary for sending query parameters, but they may be needed depending on the server configuration
      headers: {
        'Content-Type': 'application/json',
      },
      // No body is needed since you're sending data in the query string
    })
    .then(response => {
      if (!response.ok) {
        
        throw new Error('Network response was not ok: ' + response.statusText);
      }
      console.log("oh");
      return response.json();
    })
    .then(data => {
      console.log('Stop message sent:', data);
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  };
  

  const handleUserIdClick = (userId) => {
    setVisibleUserId(prevVisibleUserId => {
      // Reset the visible subfolder count when closing or opening a different user folder
      if (prevVisibleUserId !== userId) {
        setVisibleSubfolderCount(10);
      }
      return prevVisibleUserId === userId ? null : userId;
    });
    setVisibleSubfolder({}); // Reset subfolders visibility
  };
  


  const handleSubfolderClick = (userId, subfolder) => {
    setVisibleSubfolder(prevVisibleSubfolder => ({
      ...prevVisibleSubfolder,
      [subfolder]: !prevVisibleSubfolder[subfolder]
    }));
     // Only fetch the status if we're opening the subfolder, not closing it
       
  };

  return (
    <div className='main-container'>

    <div className='gradient-text'>
    
    </div>
     <NavbarDemo />
 
    <div className='file-container'>
      <div className='header-line'></div>
      {Object.keys(userFolders).map((userId) => (
        <div key={userId}>
          <button onClick={() => handleUserIdClick(userId)} className="user-button">
            {userId}
          </button>
          {visibleUserId === userId && Object.keys(userFolders[userId]).slice(0, visibleSubfolderCount).map((subfolder) => (
            <div key={subfolder}>
              <div className="job-container">
                <div style={{ flex: 1 }}>
                  {jobStatus[`${userId}/${subfolder}`] && jobStatus[`${userId}/${subfolder}`].link ? (
                    <a href={jobStatus[`${userId}/${subfolder}`].link} target="_blank" rel="noopener noreferrer" className="complete-link">
                      {subfolder} (Complete)
                    </a>
                  ) : (
                    <button onClick={() => handleSubfolderClick(userId, subfolder)} className="subfolder-button">
                      {subfolder} - {jobStatus[`${userId}/${subfolder}`] && jobStatus[`${userId}/${subfolder}`].status ? jobStatus[`${userId}/${subfolder}`].status : 'Checking...'}
                    </button>
                  )}
                </div>
                
                <div className='ml-3 '>
                <Button onClick={() => sendStopMessage(`${userId}/${subfolder}`)}
        borderRadius="0.5rem"
        className="bg-transparent dark:bg-slate-900 dark:text-white border-neutral-200 dark:border-slate-800 hover:bg-red-900/80"
      >
        Stop Job
      </Button>
      </div>
              </div>
              {visibleSubfolder[subfolder] && (
                <ul>
                  {userFolders[userId][subfolder].map((filePath) => (
                    <li key={filePath}>
                      <a
                        href={`https://artiamas-testing-bucket.s3.us-east-1.amazonaws.com/${userId}/${subfolder}/${filePath}`}
                        className="file-item"
                        target="_blank"
                        rel="noopener noreferrer"
                        download
                      >
                        {filePath}
                      </a>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          ))}
          {visibleUserId === userId && Object.keys(userFolders[userId]).length > visibleSubfolderCount && (

                      <button onClick={() => setVisibleSubfolderCount(visibleSubfolderCount + 10)} className="px-2 ml-8 mt-2 py-2 rounded-md border border-neutral-300 bg-neutral text-white text-sm hover:-translate-y-1 transform transition duration-200 hover:shadow-md">
                        Show More
                      </button>
                    )}


        </div>
      ))}
    </div>
  </div>
);
  
};

export default S3FileViewer;


