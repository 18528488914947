// LoginPage.js
import React, { useState } from 'react';
import { useEffect } from 'react'; 
//import './loginpage.css'; 

import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';


const LoginPage = () => {
  const { login,logout } = useAuth(); 
  

  const history = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loginErrorMessage, setLoginErrorMessage] = useState('');
  
  useEffect(() => {
  const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
  if (!isLoggedIn) {

     // Set the login state to true in your context
    history('/'); // Redirect to the main page if already logged in
  }
  // Remove the logout() function from here
}, [login, history]);

  const handleLogin = async () => {
    try {
      if (!username.trim() || !password.trim()) {
        console.error('Username or password cannot be empty');
        setLoginErrorMessage('Username or password cannot be empty');
        return; // Exit the function early
      }
   
      const url = `https://artiamas-cloud-viewer.org/login_user?username=${username}&password=${password}`;
      const response = await fetch(url, {
        method: 'GET',
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }else{
        const stuff = await response.text();
        // Assuming you receive the token from the login API response
       

        if (!(await stuff).includes("true")){
          throw new Error(`Declined`);
        }else{
          login();
          const responseObject = JSON.parse(stuff);
          const token = responseObject.token;
          localStorage.setItem('isLoggedIn', 'true');
          localStorage.setItem('token', token);
          console.log("token",token); 
          localStorage.setItem("Username",username); 
          // Persist the username as needed or store it in a more secure place

          history('/s3-file-viewer'); // set login state
      
        // Redirect to S3FileViewer after successful login
        }

      }
      

      

    } catch (error) {
      console.error('Error during login:', error.message);
      setLoginErrorMessage('Invalid login credentials');
    
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleLogin();
    }
  };

  

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="max-w-md w-full space-y-8 p-10 bg-white rounded-xl shadow-lg z-10">
        <div className="text-center">
          <h2 className=" mb-10 text-3xl font-bold text-gray-900">
            Login Page
          </h2>
        
        </div>
        <form className="mt-8 space-y-6" action="#" method="POST">
          <input type="hidden" name="remember" defaultValue="true" />
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="username" className="sr-only">Username</label>
              <input
                id="username"
                name="username"
                type="text"
                autoComplete="username"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                onKeyPress={handleKeyPress}
              />
            </div>
            <div>
              <label htmlFor="password" className="sr-only">Password</label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onKeyPress={handleKeyPress}
              />
            </div>
          </div>

          <div>
            <button
              type="button"
              onClick={handleLogin}
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-800 focus:outline-none focus:ring-0 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Login
            </button>
            
          </div>
          {loginErrorMessage && (
            <p className="text-red-500 flex justify-center">{loginErrorMessage}</p>
          )}
        </form>
      </div>
    </div>
  );
};
export default LoginPage;