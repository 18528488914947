import React, { useState } from 'react';

const ScenicPage = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [responseMsg, setResponseMsg] = useState('');
    const [functionResult, setFunctionResult] = useState('');
    const [imageUrl, setImageUrl] = useState(''); // State to hold the image URL

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleUpload = async () => {
        if (selectedFile) {
            try {
                const username = localStorage.getItem('Username');
                const token = localStorage.getItem('token');
                const folder_name = 'DefaultFolder';
            
                const formData = new FormData();
                formData.append('file', selectedFile);
            
                const url = `https://artiamas-cloud-viewer.org/s3-upload?username=${username}&token=${token}&folder_name=${folder_name}`;
            
                const response = await fetch(url, {
                    method: 'POST',
                    body: formData,
                });
                setResponseMsg("File Uploaded!"); 
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
            
                const responseData = await response.json();
                console.log('Upload successful:', responseData);
            } catch (error) {
                console.error('Upload failed:', error.message);
                setResponseMsg("Upload Failed."); 
            }
        } else {
            console.error('No file selected for upload.');
            setResponseMsg('No file selected for upload.');
        }
    };

    const handleButtonClick = async () => {
      if (selectedFile) {
          const response = await fetch('http://52.91.239.136:5000/run_function', {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify({ filename: selectedFile.name })
          });
          if (response.ok) {
              const data = await response.json();
              console.log(data);
              setFunctionResult(data.result);
              // Append a timestamp to the image URL to force the browser to reload it
              const timestamp = new Date().getTime();
              const newImageUrl = `http://52.91.239.136:5000/images/${selectedFile.name.replace('.scenic', '.png')}?timestamp=${timestamp}`;
              setImageUrl(newImageUrl);
          } else {
              console.error(`HTTP error! Status: ${response.status}`);
              setFunctionResult('Failed to run function.');
          }
      } else {
          setFunctionResult('No file selected to run.');
      }
  };
  

    return (
        <div className="max-w-md mx-auto mt-10 bg-white p-8 border border-gray-200 rounded-lg shadow-lg">
            <h1 className="text-xl font-bold text-center mb-4">Scenic File Upload and Process</h1>
            <div className="flex flex-col items-center">
                <input
                    type="file"
                    onChange={handleFileChange}
                    className="mb-4 block w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer dark:text-gray-400 focus:outline-none focus:border-transparent dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                />
                <button
                    onClick={handleUpload}
                    className="px-6 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                    Upload File
                </button>
                <button
                    onClick={handleButtonClick}
                    className="mt-4 px-6 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                >
                    Generate Scene
                </button>
                <p className="text-black pt-5 flex justify-center">{responseMsg}</p>
                <p className="text-black pt-5 flex justify-center">{functionResult}</p>
                {imageUrl && (
                    <img src={imageUrl} alt="Result" className="mt-4 max-w-full h-auto" />
                )}
            </div>
        </div>
    );
};

export default ScenicPage;