"use client";
import React, { useState } from "react";
import { HoveredLink, Menu, MenuItem, ProductItem } from "./floating-navbar.tsx";
import { cn } from './cn.ts';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../AuthContext'; 

export function NavbarDemo() {
 
  return (
    <div className="relative w-full flex items-center justify-center">
      <Navbar className="top-2" />
 
    </div>
  );
}

function Navbar({ className }: { className?: string }) {
    const navigate = useNavigate();
    const { logout } = useAuth(); 
  
    const handleLogout = () => {
      logout(); // Call the logout function from AuthContext
      navigate('/', { replace: true }); // Navigate to the root path ('/') after logging out
    };
    const instances = () => {
        navigate('/instances',{ replace: true });
    }
  const [active, setActive] = useState<string | null>(null);
  return (
    <div
      className={cn("fixed top-10 inset-x-0 max-w-2xl mx-auto z-50", className)}
    >
      <Menu setActive={setActive}>
        <MenuItem setActive={setActive} active={active} item="Logout">
        
         
         
          <div className="flex flex-col space-y-4 text-sm">
          <HoveredLink onClick={handleLogout} className="text-sm text-white cursor-pointer hover:underline">
            Logout Here
          </HoveredLink>
           {/*  <HoveredLink href="/interface-design">Interface Design</HoveredLink>
            <HoveredLink href="/seo">Search Engine Optimization</HoveredLink>
            <HoveredLink href="/branding">Branding</HoveredLink> */}
          </div>
        </MenuItem>

   
        <MenuItem  setActive={setActive} active={active} item="Instances & Scenic" >
   

          <div className="  text-sm grid grid-cols-1 gap-10 p-4">
          <button onClick={instances}>
            <ProductItem
              title="Track Live Instances"
              href="https://cloudview.artiamas-cloud-viewer.org/instances"
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b9/AWS_Simple_Icons_Compute_Amazon_EC2_Instances.svg/1024px-AWS_Simple_Icons_Compute_Amazon_EC2_Instances.svg.png"
              description="Live EC2 instance tracker with customizable time input."
            />
            </button>
            
             <ProductItem
              title="Scenic"
              href="https://cloudview.artiamas-cloud-viewer.org/scenic"
             // src="https://assets.aceternity.com/demos/tailwindmasterkit.webp"
              description="Create, iterate, and view test scenarios "
            />
            {/* <ProductItem
              title="Moonbeam"
              href="https://gomoonbeam.com"
              src="https://assets.aceternity.com/demos/Screenshot+2024-02-21+at+11.51.31%E2%80%AFPM.png"
              description="Never write from scratch again. Go from idea to blog in minutes."
            />
            <ProductItem
              title="Rogue"
              href="https://userogue.com"
              src="https://assets.aceternity.com/demos/Screenshot+2024-02-21+at+11.47.07%E2%80%AFPM.png"
              description="Respond to government RFPs, RFIs and RFQs 10x faster using AI"
            />  */}
          </div>
        </MenuItem>
        


        <MenuItem setActive={setActive} active={active} item="View Files">
          <div className="flex flex-col space-y-4 text-sm">
            <HoveredLink to ="https://cloudview.artiamas-cloud-viewer.org/s3-file-viewer">S3 File Viewer</HoveredLink>
          
          </div>
        </MenuItem>
      </Menu>
    </div>
  );
}
