// App.js
import React,{ useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoginPage from './loginpage';
import S3FileViewer from './S3FileViewer';
import { AuthProvider } from './AuthContext';
import { useLocation } from 'react-router-dom';
import { useAuth } from './AuthContext'; // Import your AuthContext hook
import InstancesPage from './InstancesPage';
import ScenicPage from './scenicpage'; 
const App = () => {


  return (
    <AuthProvider> 
    <Router>
      <Routes>
        <Route
          path="/"
          element={<LoginPage/>}/>
        <Route path="/s3-file-viewer" element={<S3FileViewer />} />
        <Route path="/instances" element={<InstancesPage/>} />
        <Route path="/scenic" element={<ScenicPage/>} />
     
      </Routes>
    </Router>
    </AuthProvider>
  );
};

export default App;
