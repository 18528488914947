import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { adminUsernames } from './adminUsers';
import {NavbarDemo} from './components/ui/NavbarDemo.tsx';
import {Button} from "./components/ui/moving-border.tsx";

const InstancesPage = () => {
  const [instances, setInstances] = useState([]);
  const [hours, setHours] = useState(24);
  const [loading, setLoading] = useState(true);
  const { isLoggedIn,logout,username} = useAuth();
  const history = useNavigate();

  useEffect(() => {
    const isUserLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
  if (!isLoggedIn) {
    history('/', { replace: true }); // Redirects to login page if not logged in
    return
  }
}, [isLoggedIn, history]); 

  useEffect(() => {
    setLoading(true);
    const url = `https://artiamas-cloud-viewer.org/get_all_instances?hours_passed=${hours}`;
    fetch(url)
      .then(response => response.json())
      .then(data => {
        setInstances(data.instances);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching instances:', error);
        setLoading(false);
      });
  }, [hours]);

  const handleHoursChange = (event) => {
    setHours(event.target.value);
  };

  return (
<div className='flex flex-col min-h-screen bg-black'>
<NavbarDemo />
<div className="flex-grow"> {/* This container holds the rest of your content */}
        <div className="max-w-4xl mx-auto px-4 py-6 mt-16 text-white"> {/* Add mt-16 to push content down */}
          <h1 className="text-2xl font-bold text-center mb-6">EC2 Instances</h1>
   
     <div className="flex justify-center mb-4">
        <label htmlFor="hours" className="mr-2 text-xl text-center ">Show instances started in the last:</label>
        <input
          type="number"
          id="hours"
          value={hours}
          onChange={handleHoursChange}
          className="border-2 border-gray-200 rounded p-1 w-25 h-8 text-black"
          min="1"
        />
        <span className="ml-2 text-xl text-center">hours</span>
      </div>

      {loading ? (
        <p className="text-center text-white">Loading...</p>
      ) : (
        
        
        <ul className="list-none bg-neutral-300 shadow-md rounded-lg p-4">
          {instances.length > 0 ? (
          
            instances.map((instance) => <li key={instance} className="mt-5 mb-5 text-black">{instance}</li>)
          ) : (
            <p className='text-black'>No instances found in the specified time range.</p>
          )}
        </ul>
      )}
        
    </div>
    </div>
    </div>
  );
};

export default InstancesPage;
